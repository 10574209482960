<template>
  <v-container v-if="!loading" fluid class="pa-0 test">
    <v-text-field
      clearable
      placeholder="Buscar evento"
      hide-details
      append-icon="mdi-calendar-search"
      v-model="search"
    ></v-text-field>
    <template v-for="[date,events] in Object.entries(eventosComp)">
      <h5>{{ new Date(date).toLocaleDateString('esp',{weekday:'long', day:'numeric', month:'short', year:'numeric'}).toLocaleUpperCase() }}</h5>
      <v-divider class="mb-3"></v-divider>
      <!-- <v-row v-for="e in events" :key="e.custom_key" class="align-center mx-0 mb-3" :style="'border-radius:10px;overflow:hidden;background-color: '+ (colors[e.custom_key.split('_').slice(-1).pop()])"> -->
      <v-row v-for="e in events" :key="e.custom_key" class="align-center mx-0 mb-3" :style="'border-radius:10px;overflow:hidden;background-color: '+ ($vuetify.theme.dark ? 'rgb(50, 50, 50)' : 'rgb(110, 110, 110)')">
        <v-col class="pa-0 ps-2" cols="10" style="font-weight: bold;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{ e.custom_key.split("_")[0] }}</v-col>
        <v-col class="pa-0 text-center" cols="1"><v-icon>{{ e.conf ? 'mdi-check-all' : 'mdi-close' }}</v-icon></v-col>
        <v-col class="pa-0" cols="1"><v-btn icon @click="active(e)"><v-icon>{{ e.active ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn></v-col>
        <v-col cols="12" v-if="e.active">
          <v-row style="border-top: 1px solid white">
            <v-col class="py-0" cols="6">Tipo evento:</v-col>
            <v-col class="py-0" cols="6">{{ e.custom_key.split("_").slice(-1).pop() }}</v-col>
            <v-col class="py-0" cols="6">Horario:</v-col>
            <v-col class="py-0" cols="6">{{ e.custom_key.split("_").slice(-2)[0] }}</v-col>
            <v-col class="py-0" cols="6">Celebracion:</v-col>
            <v-col class="py-0" cols="6">{{ e.details["tipoEventoCustom"] || '' }}</v-col>
            <v-col class="py-0" cols="6">Hº camareros, lugar de cita:</v-col>
            <v-col class="py-0" cols="6">{{ e.details["Hº CAMAREROS Y LUGAR DE CITA"] || '' }}</v-col>
            <v-col class="py-0" cols="6">Medios de transporte:</v-col>
            <v-col class="py-0" cols="6">{{ e.details["MEDIOS TRANSPORTE"] || '' }}</v-col>
            <v-col class="py-0" cols="6">Uniforme:</v-col>
            <v-col class="py-0" cols="6">{{ e.details["UNIFORME CAMARERO"] || '' }}</v-col>
            <v-col class="py-0" cols="12"><v-divider></v-divider></v-col>
            <v-col class="py-0" cols="6">Observaciones:</v-col>
            <v-col class="py-0" cols="6"><v-icon @click="addObs(e)">mdi-note-edit</v-icon></v-col>
            <v-col class="py-0 ms-5" cols="12" v-if="e.obs">{{ e.obs }}</v-col>
            <v-col class="text-center" cols="12"><v-btn @click="confirm(e)" :color="e.conf ? 'error' : 'primary'">{{ e.conf ? 'CANCELAR ASISTENCIA' : 'CONFIRMAR ASISTENCIA' }}</v-btn></v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <AddNote ref="addNote" />
  </v-container>
  <v-container v-else>
    <div style="width: 100%;height: 90vh;" class="d-flex align-center justify-center">
      <v-progress-circular
        size="250"
        indeterminate
        color="primary"
      >
        <h2>Cargando</h2>
      </v-progress-circular>
    </div>
  </v-container>
</template>

<script>

export default {
  components: {
    AddNote: () => import("../components/AddNote.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      loading: false,
      search: '',
      menu: false,
      eventos:{},
      colors:{
        Boda: "#b51442",
        Comunión: "#f77102",
        Evento: "#009e1d",
        Corporativo: "#2990ff",
        "Pruebas boda": "#f0be1a"
      }
    };
  },
  async mounted() {
    this.loading = true
    await Promise.all([
      this.getEventos(),
    ])
    this.loading = false
  },
  computed:{
    eventosComp(){
      const formattedSearch = this.search.replaceAll(/[ _-]/g, "");
      if (!formattedSearch) return this.eventos
      else{
        const filteredEventos = {}
        Object.keys(this.eventos).forEach(date => {
          filteredEventos[date] = this.eventos[date].filter(event => {
            const formattedCustomKey = event.custom_key.replaceAll(/[ _-]/g, "");
            return formattedCustomKey.includes(formattedSearch);
          });
        });
        return filteredEventos
      }
    }
  },
  methods: {
    async addObs(e){
      const newNote = (await this.$refs["addNote"].open(
        "Añadir observaciones",
        { color: "primary" },
        e.obs
      ));
      if (!newNote) return
      try{
        await Promise.all([
          axios({
            method: "post",
            url: `/eventos/equipo/obs`,
            data: {custom_key: e.custom_key, date: e.date, obs: newNote},
          })
        ]);

        this.$set(e, 'obs', newNote);
        this.$root.$emit("snack", "Observaciones guardadas")
      }
      catch (e){
        console.log(e);
        this.$set(e, 'obs', '');
        this.$root.$emit("snack", "Error al guardar las observaciones.")
      }
    },
    async confirm(e){
      try{
        await Promise.all([
          axios({
            method: "post",
            url: `/eventos/equipo/conf`,
            data: {custom_key : e.custom_key, date: e.date, conf: !e.conf},
          })
        ]);
        this.$set(e, 'conf', !e.conf);
        if (e.conf)
          this.$root.$emit("snack", "Asistencia confirmada")
        else
          this.$root.$emit("snack", "Asistencia cancelada")
      }
      catch (e){
        console.log(e);
        this.$root.$emit("snack", "Error al confirmar la asistencia.")
      }
    },
    active(e){
      var act = e.active
      Object.keys(this.eventos).forEach(date => {
        this.eventos[date].forEach(event => {
          this.$set(event, "active", false);
          if (event === e) {
            this.$set(event, "active", !act);
          }
        });
      });
    },
    async getEventos() {
      this.loading = true
      this.$set(this.$data, 'eventos', {})
      const { data } = await axios({
        url: `/eventos/equipo`,
      });
      
      data.forEach(evento => {
        if (!this.eventos[evento.date]) this.eventos[evento.date] = []
        this.eventos[evento.date].push(evento)
      });
      const entries = Object.entries(this.eventos);

      entries.sort((a, b) => new Date(a[0]) - new Date(b[0]));

      this.eventos = Object.fromEntries(entries);
      this.loading = false
    },
  },
};
</script>

<style>
.calDay{
  height: 40px !important;
  width: 40px !important;
  transition: background-color 0.1s ease;
}
.dark-badge .v-badge__badge .v-icon {
  color: black;
}
.light-badge .v-badge__badge .v-icon {
  color: white;
}
</style>